<template>
  <div class="page" id="auto-dialer-list">
    <div class="d-flex justify-content-between align-items-center py-2 header">
      <h1 class="m-0">{{ $t('auto-dialer.list.title') }}</h1>
      <router-link to="/auto-dialer/create" class="px-4 py-3 btn btn-primary">
        {{ $t('auto-dialer.list.create') }}
      </router-link>
    </div>

    <div class="content">
      <data-table
        ref="campaigns"
        :data="campaigns"
        :fields="translatedTableFields"
        :loading="isLoading"
        :pagination="pagination"
        @changePage="onPageChange"
      >
        <template v-slot:status="{ row }">
          <status-cell :rowData="row" />
        </template>
        <template v-slot:actions="{ row }">
          <actions-cell
            :rowData="row"
            @refetch="fetchAutoDialerCampaigns({ loading: true })"
          />
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import DataTable from '../../../elements/Table.vue'
import AutoDialerService from '../../../services/autoDialer.service'
import ActionsCell from './cells/ActionsCell.vue'
import StatusCell from './cells/StatusCell.vue'

let interval

export default {
  components: {
    DataTable,
    ActionsCell,
    StatusCell
  },
  data() {
    return {
      campaigns: [],
      isLoading: false,
      tableFields: [
        {
          accessor: 'name',
          header: 'auto-dialer.list.table.name'
        },
        {
          accessor: 'status',
          header: 'auto-dialer.list.table.status'
        },
        {
          accessor: 'actions',
          header: 'auto-dialer.list.table.actions',
          width: '40%'
        }
      ],
      pagination: {
        totalPages: 0,
        page: 1
      }
    }
  },
  computed: {
    translatedTableFields() {
      return this.tableFields.map(field => {
        return {
          ...field,
          header: this.$t(field.header)
        }
      })
    }
  },
  methods: {
    onPageChange(page) {
      this.pagination.page = page
      this.fetchAutoDialerCampaigns({ loading: true })
    },
    async fetchAutoDialerCampaigns({ loading = true }) {
      if (loading) this.isLoading = true
      try {
        const res = await AutoDialerService.listCampaigns(this.pagination.page)
        const { campaigns, ...pagination } = res.data
        this.campaigns = campaigns
        this.pagination = {
          ...this.pagination,
          ...pagination
        }
      } catch (error) {
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted() {
    this.fetchAutoDialerCampaigns({ loading: true })
    interval = setInterval(() => {
      this.fetchAutoDialerCampaigns({ loading: false })
    }, 10000)
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(interval)
    next()
  }
}
</script>

<style lang="scss" scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: spin 1s linear infinite;
}
</style>
